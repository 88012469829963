@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap%27');


:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(205, 86%, 17%);
    --clr-primary-2: hsl(205, 77%, 27%);
    --clr-primary-3: hsl(205, 72%, 37%);
    --clr-primary-4: hsl(205, 63%, 48%);
    /* primary/main color */
    --clr-primary-5: hsl(205, 78%, 60%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(205, 89%, 70%);
    --clr-primary-7: hsl(205, 90%, 76%);
    --clr-primary-8: hsl(205, 86%, 81%);
    --clr-primary-9: hsl(205, 90%, 88%);
    --clr-primary-10: hsl(205, 100%, 96%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --transition: all 0.3s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 620px;
  }
  /*
  =============== 
  Global Styles
  ===============
  */
  
  *,
  ::after,
  ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";    background: var(--clr-grey-10);
    color: var(--clr-grey-1);
    line-height: 1.5;
    font-size: 0.875rem;
    
  }
  ul {
    list-style-type: none;
  }
  a {
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: var(--spacing);
    text-transform: capitalize;
    line-height: 1.25;
    margin-bottom: 0.75rem;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 0.875rem;
  }
  p {
    margin-bottom: 1.25rem;
    color: var(--clr-grey-5);
  }
  @media screen and (min-width: 800px) {
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1rem;
    }
    body {
      font-size: 1rem;
    }
    h1,
    h2,
    h3,
    h4 {
      line-height: 1;
    }
  }
  /*  global classes */
  
  /* section */
  .section {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  
  @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  }
  
  /*
  =============== 
  Navbar
  ===============
  */
  .bg-colored {
    background: rgba(255,255,255, 0.85);
    box-shadow: var(--light-shadow);
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .bg-none {
    background: rgba(255,255,255, 0.85);
    box-shadow: var(--light-shadow);
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-toggle {
    font-size: 2.625rem;
    color: var(--clr-red-dark);
    background: transparent;
    border-color: transparent;
    transition: var(--transition);
    cursor: pointer;
  }
  .nav-toggle:hover {
    color: var(--clr-primary-1);
    transform: rotate(90deg);
  }
  .logo {
    height: 40px;
  }
  .links a {
    font-family: "Roboto Slab",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: var(--clr-red-dark);
    font-size: 1.625rem;
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    display: block;
    padding: 0.5rem 1rem;
    transition: var(--transition);
    font-weight: 600;
  }
  .links a:hover {
    background: var(--clr-red-dark);
    color: white;
    padding-left: 1.5rem;
  }
  .social-icons {
    display: none;
  }
  .links-container {
    height: 0;
    overflow: hidden;
    transition: var(--transition);
  }
  .show-container {
    height: 10rem;
  }

  .nav-header a {
    font-family: "Roboto Slab",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: var(--clr-red-dark)!important;
    font-size: 2.625rem;
    line-height: 0;
    font-weight: 600;
}

  @media screen and (min-width: 800px) {
    .bg-colored {
        background: rgba(255,255,255, 0.85);
        box-shadow: var(--light-shadow);
        z-index: 5;
        position: fixed;
        top: 0;
        width: 100%;
      }

      .bg-none {
        background: transparent;
        box-shadow: none;
        z-index: 5;
        position: fixed;
        top: 0;
        width: 100%;
      }

    .nav-center {
      margin: 0 auto;
      display: flex;
      padding: 0.5rem;
    }
    .nav-header {
      padding: 0;
      color: var(--clr-red-dark)!important;
      flex-basis: 30%;
      justify-content: center;
    }
    .nav-toggle {
      display: none;
    }
    .links-container {
      height: auto !important;
      flex-basis: 57%;
      display: flex; 
      justify-content: flex-end;
      margin-top: 2vh;
    }
    .links {
      display: flex;
      align-self: center;
    }

    .nav-header a {
        color: var(--clr-red-dark)!important;
        font-size: 2.625rem;
        line-height: 0;
    }

    .links a {
      padding: 0;
      margin: 0 0.5rem;
      font-size: 1.625rem;
      color: var(--clr-red-dark)!important;
    }
    .links a:hover {
      padding: 0;
      background: transparent;
      color: var(--clr-red-dark)!important;
    }
    .social-icons {
      display: flex;
      margin-top: 2vh;
    }

    .social-icons a {
      color: white;
      font-size: 1.25rem;
      margin-bottom: 0.8vh;
      transition: var(--transition);
    }

    .social-icons a:hover {
      color: #e6e6e6;
    }

    .circles-social {
        background: var(--clr-red-dark);
        border-radius: 50%;
        height: 30px;
        width: 30px;
        transition: all 100ms ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0.5vw;
    }
    
    .circles-social:hover {
        background: #800000;
    }
  }